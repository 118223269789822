import React from "react";
import Head from "../components/head";
import Cs1 from "../components/pages/contact/cs1/cs1";
import Cs2 from "../components/pages/contact/cs2/cs2";
const Contact = () => (
  <>
    <Head dis="Set up as Gujarat's finest IELTS Training Institute with dedicated, Student Centric faculty and adopting 21st Century training techniques imparting both Online/Offline Training." />
    <Cs1 />
    <Cs2 />
  </>
);

export default Contact;
