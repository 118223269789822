import React from "react";

export default () => {
  return (
    <div className="cs2">
      <div className="cs2-cont">
        <form netlify>
        <input type="hidden" name="contact" value="contact"></input>
          <div className="input-cont">
            <label htmlFor="fullName">Full Name</label>
            <input type="text" name="fullName" required />
          </div>
          <div className="input-cont">
            <label htmlFor="MobileNumber">Mobile</label>
            <input type="number" name="MobileNumber" required />
          </div>
          <div className="input-cont">
            <label htmlFor="email">Email Id</label>
            <input type="email" name="email" required />
          </div>

          <label htmlFor="textarea">Any message for us</label>
          <textarea name="texarea" id="" cols="30" rows="10"></textarea>
          <button type="submit">SUBMIT</button>
        </form>
      </div>
    </div>
  );
};
