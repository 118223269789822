import React from "react";
import { Helmet } from "react-helmet";
export default (props) => {
  return (
    <Helmet>
      <meta
        name="google-site-verification"
        content="kUAdfIPBBAs-7Nyd40F6zZ5Dha-ZXbejF8m5cdLauOo"
      />
      <meta charSet="utf-8" />

      <title>{props.title ? `${props.title} | OCSO` : "OCSO"}</title>

      <meta name="description" content={`${props.dis}`} />
      <meta
        property="og:title"
        content="One of the Gujarat's leading OET institute recognised by OET(Australia) in Ahmedabad. Magdeline's Academy is also a One stop shop for providing OET Training to Nurses, Doctors, Dentists, Pharmacists."
      />
      <meta property="og:locale" content="uk_ua" />
      <meta property="og:url" content="https://magdelineacademy.in/" />
      <meta property="og:type" content="Website" />
      <meta
        property="og:description"
        content="One of the Gujarat's leading OET institute recognised by OET(Australia) in Ahmedabad. Magdeline's Academy is also a One stop shop for providing OET Training to Nurses, Doctors, Dentists, Pharmacists."
      />
    </Helmet>
  );
};
