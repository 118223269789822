import React from "react";
import { IconContext } from "react-icons";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { MdLocationOn, MdCall, MdMailOutline } from "react-icons/md";

export default () => {
  return (
    <div className="cs1">
      <div className="cs1-cont">
        <div className="cs1-icons">
          <IconContext.Provider value={{ className: "about-i" }}>
            <a
              href="https://www.facebook.com/ocsointernational/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF value={{ style: { verticalAlign: "middle" } }} />
            </a>
          </IconContext.Provider>
          {/* <IconContext.Provider value={{ className: "about-i" }}>
            <a
              href="https://twitter.com/RaundEmbroidery/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
          </IconContext.Provider> */}
          <IconContext.Provider value={{ className: "about-i" }}>
            <a
              href="https://www.instagram.com/ocso_international/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </IconContext.Provider>
        </div>
        <div className="cs1-main">
          <div>
            <IconContext.Provider value={{ className: "about-i-m" }}>
              <a
                href="https://www.instagram.com/raunds_embroidery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdMailOutline />
              </a>
            </IconContext.Provider>
            <h5>Email Here</h5>
            <a href="mailto:support@ocso.in">support@ocso.in</a>
     
          </div>
          <div>
            <IconContext.Provider value={{ className: "about-i-m" }}>
              <a
               
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdLocationOn />
              </a>
            </IconContext.Provider>
            <h5>Reach Us Here</h5>
            <a >
              2/3 Jagnath plot, Yagnik road, Rajkot
            </a>
          </div>
          <div>
            <IconContext.Provider value={{ className: "about-i-m" }}>
              <a
                href="tel:9925050564"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdCall />
              </a>
            </IconContext.Provider>
            <h5>Call Us</h5>
            <a href="tel:9925050564">9925050564
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};
